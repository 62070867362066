.discover .content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}

.discover .box {
  background-color: #fff;
  box-shadow: 0 4px 8px rgb(0 0 0 / 4%), 0 0 2px rgb(0 0 0 / 6%), 0 0 1px rgb(0 0 0 / 4%);
}
.discover .img {
  width: 100%;
  height: 100px;
}
.discover img {
  width: 100%;
  height: 100px;
  object-fit: cover;

}
.discover h1 {
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .discover .content {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Medium Screens (Tablet and below) */
@media screen and (max-width: 1024px) {
  .discover .content {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for medium-sized screens */
  }

  .discover h1 {
    font-size: 1.3rem; /* Adjust heading font size for medium screens */
  }
}

/* Smaller Screens (Mobile Devices) */
@media screen and (max-width: 768px) {
  .discover .content {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for small screens */
  }

  .discover h1 {
    font-size: 1.2rem; /* Adjust heading font size for small screens */
  }

  .discover .img {
    width: 100%; /* Make the image 100% width on small screens */
    height: auto; /* Adjust the height to maintain aspect ratio */
  }
}

/* Very Small Screens (Mobile Phones, Portrait) */
@media screen and (max-width: 480px) {
  .discover .content {
    grid-template-columns: 1fr; /* Single column layout for very small screens */
  }

  .discover h1 {
    font-size: 1.1rem; /* Further reduce heading size for very small screens */
  }

  .discover .img {
    width: 100%; /* Full width for images on very small screens */
    height: 150px; /* Adjust image height for better fit */
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .discover img {
    max-width: 100%; /* Ensure images do not exceed the container width */
    height: auto; /* Maintain aspect ratio */
  }
}
