/* Updated Gallery Image Styles for Responsiveness */
.gallery .img {
  width: 100%;
  max-width: 315px;
  height: auto;
}

.gallery img {
  width: 100%;
  max-width: 315px;
  height: auto;
  object-fit: cover;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .gallery .img,
  .gallery img {
    max-width: 100%; /* Full width on small screens */
    height: auto; /* Maintain aspect ratio */
  }
}

/* Social Box Styles */
.social .socBox {
  padding: 10px 20px;
  margin-bottom: 10px;
  color: #fff;
  display: flex;
  align-items: center;
}

.social .socBox i {
  margin-right: 10px;
}

.social .socBox span {
  font-weight: 500;
}

.social .socBox:nth-child(1) {
  background-color: #3b5998;
}

.social .socBox:nth-child(2) {
  background-color: #bd081c;
}

.social .socBox:nth-child(3) {
  background-color: #1da1f2;
}

.social .socBox:nth-child(4) {
  background-color: #c32aa3;
}

.social .socBox:nth-child(5) {
  background-color: #ff0000;
}

/* Subscribe Section */
.subscribe {
  border: 1px solid black;
  padding: 20px;
  text-align: center;
}

.subscribe h1 {
  text-align: left;
  font-size: 20px;
}

.subscribe input {
  width: 100%;
  padding: 10px;
}

.subscribe button {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #ff0000;
  color: #fff;
  font-weight: 600;
  border: none;
}

/* Banner */
.banner {
  margin: 40px 0;
}

/* Category Section */
.catgorys .category1 {
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
}

/* Responsive adjustments for categories-side */
.categories-side {
  background-image: url("../../../../../public/images/title_pattern.png");
  background-position: center;
  margin: 30px 0;
}

.categories-side span {
  background-color: black;
  padding: 3px 10px;
}

/* Responsive styles for all screen sizes */

/* Adjust layout for smaller screens */
@media screen and (max-width: 992px) {
  /* Social Box */
  .social .socBox {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  /* Subscribe Section */
  .subscribe {
    padding: 15px;
  }

  /* Banner */
  .banner {
    margin: 20px 0;
  }
}

/* Adjustments for mobile screens */
@media screen and (max-width: 768px) {
  /* Subscribe Section */
  .subscribe h1 {
    font-size: 18px;
  }

  .subscribe input,
  .subscribe button {
    padding: 8px;
  }

  /* Gallery Images */
  .gallery .img,
  .gallery img {
    max-width: 100%; /* Full width for mobile */
    height: auto;
  }

  /* Category Section */
  .catgorys .category1 {
    padding: 8px;
  }
}

/* Adjustments for extra small screens */
@media screen and (max-width: 576px) {
  .subscribe input,
  .subscribe button {
    font-size: 14px;
  }

  .social .socBox {
    padding: 8px 15px;
  }

  .banner {
    margin: 10px 0;
  }

  .categories-side {
    padding: 15px;
  }
}
