/* sidebar.css */

/* Sidebar Container */
.sidebar {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensures full width on smaller screens */
}

/* Recent Posts Section */
.recent-posts h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.recent-posts ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.recent-post-item {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.recent-post {
  text-align: start !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-post-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
  width: 100%;
}

.recent-post-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-right: 5px;
}

.recent-post-title {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bolder;
  color: #333;
  margin: 0;
}

/* Categories Section */
.categories h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
}

.categories ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.categories li {
  background-color: #007bff;
  color: #fff;
  padding: 8px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.categories li:hover {
  background-color: #0056b3;
}

.categories a {
  text-decoration: none;
  color: #fff;
}

.categories a:hover {
  text-decoration: underline;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .sidebar {
      padding: 15px;
      align-items: flex-start;
  }
  .recent-post-item, .recent-post-link {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }
  .recent-post-image {
      width: 60px;
      height: 60px;
      margin-bottom: 8px;
  }
  .recent-post-title {
      font-size: 18px;
      margin: 0;
  }
  .categories li {
      padding: 6px 10px;
      font-size: 13px;
  }
}

@media (max-width: 480px) {
  .sidebar {
      padding: 10px;
  }
  .recent-posts h3, .categories h3 {
      font-size: 18px;
  }
  .recent-post-image {
      width: 50px;
      height: 50px;
      margin-bottom: 6px;
  }
  .recent-post-title {
      font-size: 16px;
  }
  .categories li {
      padding: 5px 8px;
      font-size: 12px;
  }
}