.weather-widget {
  
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
  width: 200px;
}

.paddingTB{
  padding: 5px !important;

}

.weather-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
}

  .weather-title {
    font-size: 1.2rem; /* Smaller title size */
    margin-bottom: 4px; /* Reduced margin */
    font-weight: 700;
  }

  .weather-icon {
    font-size: 1.8rem; /* Smaller weather icon */
  }
  
  .weather-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
  }
  
  .temperature {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 3px;
  }
  
  .wind-info {
    font-size: 0.8rem;
    color: #555;
  }
  
  .wind-speed {
    font-weight: bold;
  }
  
  .wind-direction {
    font-style: italic;
    color: #504b4b;
  }