/* Main hero styling (unchanged for desktop) */
.hero {
  margin: 20px 0;
}

.hero .box {
  position: relative;
  padding: 30px;
}

.titleBg {
  color: white;
}
.author{
  color: white;
}
a {
  color: white;
}
.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.8; /* Adjust opacity here */
  filter: brightness(50%);
}
.hero .container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 6.6vh);
  grid-gap: 10px;
}
.hero .box:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 9;
}
.hero .box:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 5;
}
.hero .box:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 9;
}
.hero .box:nth-child(4) {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 9;
}
.hero .text h1 {
  font-weight: 500;
  margin: 10px 0;
  font-size: 15px;
}
.hero .author span {
  font-size: 14px;
  margin-right: 20px;
}
.hero .text {
  position: absolute;
  bottom: 30px;
}

/* Category color adjustments */
.hero .box:nth-child(1) .category {
  background-color: crimson;
}
.hero .box:nth-child(2) .category {
  background-color: #0b9931;
}
.hero .box:nth-child(4) .category {
  background-color: #c78823;
}

/* Media query for mobile devices (max-width: 768px) */
@media screen and (max-width: 768px) {
  .hero .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 10px;
  }
  
  .hero .box {
    padding: 20px;
    margin-bottom: 10px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.6); /* Slightly dark background for readability */
  }

  .hero img {
    margin-bottom: 10px; /* Adds spacing between the image and the text */
    position: relative;
    top: 0;
    left: 0;
    object-fit: cover;
    height: auto;
  }

  .hero .text {
    position: relative;
    bottom: 0; /* Removes the previous bottom position */
    padding-top: 10px; /* Adds top padding */
    text-align: center; /* Centers the text */
  }

  .hero .text h1 {
    font-size: 20px; /* Larger title size for mobile */
    margin: 5px 0;
  }

  .hero .author span {
    font-size: 12px; /* Smaller font size for mobile */
    margin-right: 10px;
  }

  /* Adjustments for the first box */
  .hero .box:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  /* Adjustments for the second box */
  .hero .box:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  /* Adjustments for the third box */
  .hero .box:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 7;
  }

  /* Adjustments for the fourth box */
  .hero .box:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 9;
  }
}
