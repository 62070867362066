.search-results {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .result-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .result-card:hover {
    transform: translateY(-5px);
  }
  
  .result-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .result-title {
    font-size: 1.2em;
    margin: 10px;
    font-weight: bold;
  }
  
  .result-excerpt {
    font-size: 0.9em;
    margin: 0 10px 10px;
    color: #555;
  }
  