/* Specific Styles for Privacy Policy */
.privacy-policy-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.privacy-policy-title {
  font-size: 2.5rem;
  color: #3b3b3b;
  margin-bottom: 20px;
  text-align: center;
}

.privacy-policy-date {
  font-size: 1rem;
  color: #777;
  text-align: center;
  margin-bottom: 30px;
}

.privacy-policy-section {
  margin-bottom: 30px;
}

.privacy-policy-heading {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 10px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 5px;
}

.privacy-policy-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  text-align: justify;
}

.privacy-policy-link {
  color: #3498db !important;
  text-decoration: none;
  font-weight: 600;
}

.privacy-policy-link:hover {
  text-decoration: underline;
}
