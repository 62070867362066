footer {
  background-color: #000;
  color: #fff;
  padding: 50px 0;
  margin-top: 50px;
}

footer .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-right: 50px;
  column-gap: 90px;
}

footer .logo p {
  margin: 20px 0;
}

.f-logo {
  width: 90px;
}

footer h3 {
  margin-bottom: 20px;
  font-weight: 500;
}

footer .item {
  display: flex;
  margin-bottom: 30px;
}

footer .item img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

footer .item a {
  color: white;
}

footer .item p {
  padding: 10px 20px;
}

.f-logo p {
  margin-bottom: 15px;
}

footer ul li {
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

footer ul li:nth-last-child(1) {
  border-bottom: none;
}

footer ul li span::before {
  content: ">";
  top: 0;
  left: 0;
  margin-right: 10px;
}

.legal {
  padding: 15px;
  background-color: #000;
  color: grey;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  text-align: center;
}

.legal i {
  color: red;
}

footer ul li a {
  color: white !important;
}

.email-link{
  color: white !important;
}

/* Medium Screen Sizes */
@media screen and (max-width: 1024px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns layout */
    grid-gap: 20px;
    margin-right: 30px;
    column-gap: 50px;
  }
}

/* Small Screen Sizes (Phones) */
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: 1fr; /* 1 column layout */
    grid-gap: 15px;
    margin-right: 20px;
    column-gap: 30px;
  }

  footer h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  footer .item img {
    width: 60px;
    height: 60px;
  }

  footer .item p {
    font-size: 14px;
    padding: 5px 10px;
  }

  footer ul li {
    margin-bottom: 15px;
    font-size: 14px;
  }

  footer .legal {
    font-size: 14px;
    padding: 10px;
  }

  .f-logo {
    width: 70px;
  }
}

/* Extra Small Screen Sizes (Very Small Phones) */
@media screen and (max-width: 480px) {
  footer .container {
    grid-template-columns: 1fr; /* 1 column layout */
    grid-gap: 15px;
    margin-right: 10px;
    column-gap: 15px;
  }

  footer h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  footer .item img {
    width: 50px;
    height: 50px;
  }

  footer .item p {
    font-size: 12px;
    padding: 5px;
  }

  footer ul li {
    font-size: 12px;
    margin-bottom: 10px;
  }

  footer .legal {
    font-size: 12px;
    padding: 5px;
  }

  .f-logo {
    width: 60px;
  }
}



