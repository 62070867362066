/* General styles for the grid layout */
.grid-layout{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.column-1{display: flex; flex-direction: row; flex-wrap: wrap; gap: 10px;}
.items{
  width: 30%;
}
.news-item{width: 30%;}
.popular .box {
  background-color: #fff;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px; /* Subtle shadow */
  position: relative;
  margin: 15px;
  transition: transform 0.3s ease-in-out; /* Smooth hover effect */
}

.popular .box:hover {
  transform: translateY(-5px); /* Lift the box on hover */
}

.popular .images {
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden; /* Ensures images stay inside the container */
  border-radius: 8px 8px 0 0; /* Rounded top corners */
}

.popular img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease; /* Smooth fade effect on hover */
}

.popular .images:hover img {
  opacity: 0.8; /* Slight fade effect on hover */
}

.popular .text {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popular .text h1 {
  font-size: 1.2rem;
  color: #333; /* Darker text for better contrast */
  font-weight: 600;
  margin-bottom: 10px;
}

.popular .date {
  font-size: 0.9rem;
  color: #777;
  margin-top: 10px;
}

.popular .comment {
  border-top: 1px solid #f0f0f0;
  padding-top: 10px;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
  display: flex;
  align-items: center;
}

.popular .comment i {
  margin-right: 5px;
  color: #007bff; /* Blue icon for the comment section */
}

.desc {
  font-size: 14px !important;
  color: #666;
  line-height: 1.6;
  margin-top: 10px;
  padding: 0 20px;
  text-align: justify;
}
/* Responsive Design for smaller screens */

/* Tablet (1024px) and below */
@media screen and (max-width: 1024px) {
  .items, .news-item {
    width: 48%; /* Adjust to 48% for medium-sized screens */
  }

  .grid-layout {
    flex-direction: column; /* Stack columns vertically */
    justify-content: flex-start; /* Align items at the top */
  }

  .column-1 {
    justify-content: flex-start; /* Align items at the top */
  }

  .popular .box {
    margin: 10px 0; /* Reduce margin between items */
  }

  .popular .text h1 {
    font-size: 1.1rem; /* Slightly smaller font for medium screens */
  }

  .desc {
    font-size: 13px; /* Smaller description font for medium screens */
  }
}

/* Mobile (768px) and below */
@media screen and (max-width: 768px) {
  .items, .news-item {
    width: 100%; /* Full width for small screens */
  }

  .grid-layout {
    flex-direction: column; /* Stack items vertically */
    padding: 10px; /* Add padding to ensure proper spacing */
  }

  .column-1 {
    justify-content: flex-start;
  }

  .popular .box {
    margin: 10px 0;
  }

  .popular .text h1 {
    font-size: 1rem; /* Smaller font size for small screens */
  }

  .desc {
    font-size: 12px; /* Further reduce description font for small screens */
  }
}

/* Mobile (480px) and below */
@media screen and (max-width: 480px) {
  .items, .news-item {
    width: 100%; /* Full width on very small screens */
    margin: 10px 0; /* Reduce margin for tighter space */
  }

  .popular .box {
    margin: 8px 0; /* Even smaller margin on tiny screens */
  }

  .popular .text h1 {
    font-size: 0.9rem; /* Decrease font size even more */
  }

  .desc {
    font-size: 11px; /* Keep the text readable but small */
  }
}