.head .logo img {
  width: 80px;
}
header {
  background-color: #000;
  color: #fff;
}
header li {
  margin-right: 30px;
  transition: 0.5s;
}
header li:hover {
  color: crimson;
  cursor: pointer;
}
header a {
  color: #fff;
}
.barIcon {
  font-size: 25px;
  background: none;
  color: #fff;
  display: none;
  border: none;
}

.search-container {
  display: flex;
  align-items: center;
  float: right;
  margin-top: -30px;
}

.search-container input[type="text"] {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
}

.search-icon {
  background-color: #333;
  color: #fff;
  padding: 8px;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.search-icon i {
  font-size: 18px;
}


@media screen and (max-width: 768px) {
  .head .ad {
    display: none;
  }
  header .icon {
    display: none;
  }
  header .flex {
    display: none;
  }
  .barIcon {
    display: block;
  }
  header .navbar {
    position: absolute;
    display: block;
    background-color: #fb4c35;
    left: 0;
    top: 27%;
    transition: 0.5s;
    width: 100%;
    z-index: 5;
    padding: 50px;
  }
  header .navbar {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  header ul li {
    margin-bottom: 30px;
  }
}
