.music .box {
  margin-bottom: 30px;
  margin-left: 2px;
  border: 1px solid rgb(187, 180, 180);
  border-radius: 12px;
  padding: 10px;
}

.music .text {
  padding: 0 30px;
}

.music .images {
  width: 100%; /* Ensure images are responsive */
  height: 220px;
  object-fit: cover;
}

.music img {
  width: 100%; /* Make the image fill the width of the container */
  height: 220px;
  object-fit: cover;
}

.title {
  color: black !important;
}

.music h1 {
  font-size: 22px;
  margin-top: 15px;
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.pagination button:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #4CAF50; /* Green background */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 0 10px;
}

.pagination button:hover {
  background-color: #45a049!important; /* Darker green on hover */
  transform: scale(1.1); /* Slightly enlarge the button */
}

.pagination button:disabled {
  background-color: #ddd; /* Gray color when disabled */
  cursor: not-allowed; /* Change the cursor to indicate disabled state */
}

.pagination .prev {
  background-color: #f44336; /* Red color for 'Previous' button */
}

.pagination .next {
  background-color: #008CBA; /* Blue color for 'Next' button */
}

/* Active Button */
.pagination button.active {
  background-color: #333;
  font-weight: bold;
}


/* Medium Screens (Tablets) */
@media screen and (max-width: 1024px) {
  .music .images,
  .music img {
    height: 250px; /* Adjust height for tablet screens */
  }

  .music h1 {
    font-size: 18px; /* Adjust font size for tablets */
  }

  .music .text {
    padding: 0 15px; /* Less padding for medium screens */
  }
}

/* Small Screens (Mobile Devices) */
@media screen and (max-width: 768px) {
  .music .images,
  .music img {
    height: 200px; /* Adjust height for mobile screens */
  }

  .music h1 {
    font-size: 16px; /* Smaller font size for mobile */
  }

  .music .text {
    padding: 0 10px; /* Less padding for smaller screens */
  }

  .pagination {
    flex-direction: column; /* Stack pagination buttons vertically */
    align-items: center;
  }

  .pagination button {
    margin: 5px 0;
    width: 100%; /* Full width pagination buttons on mobile */
  }
}

/* Very Small Screens (Portrait Mobile) */
@media screen and (max-width: 480px) {
  .music .images,
  .music img {
    height: 180px; /* Adjust height for very small screens */
  }

  .music h1 {
    font-size: 14px; /* Very small font size for portrait mobile */
  }

  .music .text {
    padding: 0 5px; /* Minimize padding on very small screens */
  }
}

/* One Slide Display for All Screen Sizes */
@media screen and (max-width: 768px) {
  .music .box {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin-left: 0;
  }

  .music .text {
    text-align: center; /* Center text for mobile */
  }
}
