.error-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    /* height: 100vh; */
    /* background-color: #f4f4f4; */
    font-family: Arial, sans-serif;
  }
  
  
  .error-page-content {
    text-align: center;
  }
  
  .error-title {
    font-size: 5rem;
    color: #ff4d4d;
  }
  
  .error-message {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
  }
  
  .error-description {
    font-size: 1rem;
    color: #666;
    margin-top: 20px;
  }
  
  .back-home-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .back-home-link:hover {
    text-decoration: underline;
  }
  