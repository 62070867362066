/* Main container for the Contact Us page */
.contact-us-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  background-color: #f4f7f6;
  padding: 10px;
}

.contact-us-content {
  max-width: 800px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.contact-us-heading {
  font-size: 2.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-us-tagline {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 30px;
}

.contact-us-email-section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}

.email-icon {
  color: #007bff;
  margin-right: 10px;
  font-size: 1.6rem;
  transition: transform 0.3s ease;
}

.email-icon:hover {
  transform: scale(1.2);
}
.email-link {
  color: #007bff !important;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.email-link:hover {
  color: #0056b3;
}

/* Responsive styling */
@media screen and (max-width: 768px) {
  .contact-us-heading {
    font-size: 1.8rem;
  }

  .contact-us-tagline {
    font-size: 1rem;
  }

  .contact-us-email-section {
    font-size: 1rem;
  }
}
