.popularPost {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.popularPost .box {
  border: 1px solid gray;
  border-radius: 12px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.popularPost .box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popularPost img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures image scales correctly */
}

.popularPost .text {
  padding: 20px;
}

/* Responsive Design */

/* Tablet (1024px) and below */
@media screen and (max-width: 1024px) {
  .popularPost {
    padding: 15px;
  }

  .popularPost .box {
    width: 48%; /* 2 items per row */
    margin: 15px 1%; /* Adjust for even spacing */
  }

  .popularPost .text {
    padding: 15px;
  }
}

/* Mobile (768px) and below */
@media screen and (max-width: 768px) {
  .popularPost {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .popularPost .box {
    width: 100%; /* 1 item per row */
    margin: 10px 0;
  }

  .popularPost .text {
    padding: 12px;
  }
}

/* Small Mobile (480px) and below */
@media screen and (max-width: 480px) {
  .popularPost .box {
    width: 100%;
    margin: 8px 0;
  }

  .popularPost img {
    height: 150px; /* Set a fixed height for uniformity */
  }

  .popularPost .text {
    padding: 10px;
    font-size: 14px; /* Adjust font size for readability */
  }
}
