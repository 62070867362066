/* SinglePage.css */

.single-page-container {
  padding: 50px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.news-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.news-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.news-header{
  text-align: start !important;
}

.news-card:hover {
  transform: translateY(-10px);
}

.news-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.news-card-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start; /* Ensures the title and description fill available space */
  height: 200px; /* Set height for the card body */
}

.news-card-body h4 {
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;
  line-height: 1.5; /* Reduced line height */
  text-overflow: ellipsis;
}

.news-card-body p {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; /* Reduced line height */
}

.read-more-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.read-more-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .news-cards-container {
    justify-content: start; /* Center the news cards on smaller screens */
  }

  .news-card {
    width: 100%;
    max-width: 350px; /* Limit the card width on smaller screens */
  }
}

.table-of-contents h5 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: bold;
}

.table-of-contents ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 15px 0;
}

.table-of-contents li {
  margin-bottom: 5px;
}

.toc-link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

.toc-link:hover {
  text-decoration: none;
}

.news-description h4 {
  margin-top: 1.5em;
  font-size: 16px;
  font-weight: bold;
}

.read-more-link{
  color: black !important;
  text-decoration: none;
}

.read-more-link:hover{
  text-decoration: none !important;
}

/* Pagination Container */
.pagination {
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Pagination Buttons */
.pagination button {
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #007bff;
  border-radius: 25px; /* Rounded edges for a soft look */
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
  text-transform: uppercase;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
}

.pagination button:hover:not(:disabled) {
  background-color: #0056b3;
  border: 1px solid #0056b3;
  box-shadow: 0 6px 12px rgba(0, 86, 179, 0.4);
  transform: translateY(-2px);
}

.pagination button:focus {
  outline: none;
}

/* Active Page Indicator */
.pagination .active {
  background-color: #0056b3;
  border: 1px solid #0056b3;
  color: white;
  font-weight: bold;
  transform: scale(1.1);
}

.pagination .active:hover {
  background-color: #004085;
  box-shadow: none;
}

/* Page Number Text */
.pagination span {
  font-size: 16px;
  font-weight: 600;
  color: #007bff;
  margin: 0 10px;
  text-transform: uppercase;
}

/* Hover Effect for Active Page */
.pagination .active:hover {
  transform: scale(1.1); /* Slight zoom effect when hovering over active page */
}
