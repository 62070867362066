/* General Styles for Desktop */
.news-detail-container {
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}

.content-with-sidebar {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.news-detail-content {
  flex: 1;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  margin-right: 20px;
}

.news-header a{
  color: #000;
}

.news-header {
  text-align: center;
  margin-bottom: 20px;
}

.news-title {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.news-meta {
  display: flex;
  justify-content: start;
  gap: 20px;
  margin-bottom: 20px;
  color: #777;
  font-size: 14px;
}

.news-date,
.news-author {
  font-weight: 600;
}

.news-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 30px;
}

.news-body {
  font-size: 16px;
  line-height: 1.6;
  color: #444;
}

.news-description {
  margin-bottom: 30px;
}

.news-description h2,
.news-description h3,
.news-description h4,
.news-description h5,
.news-description h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 900;
  color: rgb(97, 97, 97);
}



.social-share-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.share-button {
  background-color: #1e90ff;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.share-button:hover {
  background-color: #0066cc;
}

.no-news {
  text-align: center;
  color: #ff4d4d;
  font-size: 18px;
}

.sidebar-container {
  width: 450px;
  flex-shrink: 0;
}

.loading-spinner,
.error-message {
  text-align: center;
  font-size: 20px;
  color: #555;
}

/* Table of Contents Button and Container */
.toc-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.toc-button:hover {
  background-color: #0056b3;
}

.toc-container {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  max-height: 300px;
  overflow-y: auto;
}

.toc-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.toc-container ul li {
  margin-bottom: 10px;
}

.toc-container ul li a {
  display: inline-block;
  text-decoration: none;
  color: #007bff;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toc-container ul li a:hover {
  background-color: #007bff;
  color: #fff !important;
  text-decoration: none !important;
}

.toc-container ul li a:active {
  background-color: #0056b3;
  color: #fff;
}

.toc-link {
  color: black !important;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .content-with-sidebar {
      flex-direction: column;
  }

  .sidebar-container {
      width: 100%;
      margin-top: 20px;
  }

  .news-detail-content {
      margin-right: 0;
  }
}

@media (max-width: 768px) {
  .news-title {
      font-size: 28px;
  }

  .news-meta {
      flex-direction: column;
      gap: 5px;
  }

  .toc-button {
      font-size: 14px;
      padding: 8px;
  }

  .toc-container ul li a {
      font-size: 14px;
      padding: 6px 10px;
  }
}

@media (max-width: 576px) {
  .news-detail-content {
      padding: 20px;
  }

  .news-title {
      font-size: 24px;
  }

  .news-image {
      width: 100%;
      height: auto;
  }

  .share-button {
      padding: 8px 16px;
      font-size: 12px;
  }

  .news-meta {
      gap: 10px;
      font-size: 12px;
  }

  .social-share-buttons {
      flex-direction: column;
  }

  .toc-button {
      font-size: 12px;
      padding: 6px 12px;
  }

  .toc-container ul li a {
      font-size: 12px;
      padding: 4px 8px;
  }

  .news-body {
      font-size: 14px;
      line-height: 1.5;
  }
}