/* Container for the About Us page */
.about-us {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Heading Style */
.about-us h1 {
  font-size: 2.5rem;
  color: #343a40;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

/* Adding a subtle underline with a box shadow effect */
.about-us h1::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 80%;
  height: 3px;
  background-color: #007bff;
  box-shadow: 0 2px 5px rgba(0, 123, 255, 0.3);
}

/* Paragraph styling */
.about-us p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #495057;
  margin-bottom: 20px;
  text-align: justify;
}

/* Special styling for the second paragraph */
.about-us p:last-child {
  font-weight: bold;
  color: #007bff;
  text-align: center;
  font-size: 1.2rem;
}

/* Add some margin to space out sections */
.about-us p + p {
  margin-top: 20px;
}

/* For responsiveness */
@media screen and (max-width: 768px) {
  .about-us {
    padding: 20px 15px;
  }

  .about-us h1 {
    font-size: 2rem;
  }

  .about-us p {
    font-size: 1rem;
  }
}
